export const patterns = {
  glider: `
      
      
      
•••   
  •   
 •    
`,
  blinker: `
      
  •   
  •   
  •   
      
      
`,
  box: `
      
 •• • 
 •    
    • 
 • •• 
      
`,
  "r-pentomino": `
      
      
   •• 
  ••  
   •  
      
`,
};
